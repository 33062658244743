<template>
    <li v-if="! isEmpty(items)" class="list-group-item p-3">
        <h5 class="fw-bold mb-3" v-if="title !== 'null'">{{ title }}</h5>
        <ul class="list-group">
            <video-list-item
                v-for="video in items"
                :key="video.id"
                :course="course"
                :video="video"
            ></video-list-item>
        </ul>
    </li>
</template>

<script>
import VideoListItem from './VideoListItem'
import { isEmpty } from 'ramda'

export default {
    components: { VideoListItem },
    computed: {
        currentVideo () {
            return this.$store.getters['video/selectCurrentVideo']
        },
    },
    methods: {
        isEmpty,
        playVideo () {
            this.$store.commit('video/setCurrentVideo', this.video)
        },
    },
    name: 'VideoListItemGroup',
    props: {
        course: String,
        items: Array,
        title: String,
    },
}
</script>
