<template>
    <video ref="video" playsinline></video>
</template>

<script>
import Plyr from 'plyr'
import { isEmpty } from 'ramda'
import { getUnixTime, parseISO } from 'date-fns'

export default {
    beforeDestroy () {
        this.plyr?.destroy()
    },
    computed: {
        accessToken () {
            return this.$store.getters['auth/selectAccessToken']
        },
        admin () {
            return this.$store.getters['auth/isAdmin']
        },
        course () {
            return this.$store.getters['course/selectCourse'](this.courseId)
        },
        courseId () {
            return this.$route.params?.courseId
        },
        packageId () {
            return this.course.package_id
        },
        video () {
            return this.$store.getters['video/selectCurrentVideo']
        },
    },
    data () {
        return {
            // expirationSent: false,
            plyr: null,
        }
    },
    mounted () {
        this.plyr = new Plyr(this.$refs.video, {
            fullscreen: {
                enabled: true,
                fallback: true,
                iosNative: true,
                container: null,
            },
            ratio: '16:9',
        })
        
        this.setPlyrSource(this.video)
    },
    methods: {
        setPlyrSource (video) {
            if (! this.plyr || isEmpty(video)) return
            
            let version = getUnixTime(parseISO(video?.updated_at))
            
            if (isNaN(version)) {
                version = getUnixTime(new Date())
            }
            
            this.plyr.source = {
                type: 'video',
                title: video?.title || 'Video',
                sources: [
                    {
                        src: `${video?.src}?token=${this.accessToken}&version=${version}`,
                    },
                ],
            }
        },
    },
    name: 'VideoPlayer',
    watch: {
        video: function (video) {
            this.setPlyrSource(video)
        },
    },
}
</script>
