<template>
    <admin-layout>
        <loader v-if="fetching || ! course"></loader>
        <div v-else>
            <h1 class="fw-bold text-center mb-4">
                {{ course.title }}
            </h1>
            <h5 v-if="!! course.description" class="text-center mb-4" v-html="course.description"></h5>
            <div class="row">
                <div class="col-12 col-lg-9 mb-4 mb-lg-0">
                    <div>
                        <h2 class="fw-bold text-center text-lg-start">{{ videoTitle }}</h2>
                        <video-player></video-player>
                        <div v-if="course.expire_at" class="text-center mt-2">
                            <!--                            <p class="fw-bold h5 m-0">{{ $t('Tento kurz Vám ostáva aktívny ešte') }} {{ expiration }}.</p>-->
                            <p class="fw-bold h5 m-0">{{ $t('Tento kurz vyprší') }} {{ expiration }}.</p>
                        </div>
                    </div>
                    <div v-if="video && !! video.description" class="mt-4">
                        <h2 class="fw-bold text-center text-lg-start">{{ $t('Popis') }}</h2>
                        <p v-html="video.description"></p>
                    </div>
                </div>
                <div class="col-12 col-lg-3">
                    <h2 class="fw-bold text-center text-lg-start">{{ $t('Zoznam videí') }}</h2>
                    <ul class="list-group">
                        <video-list-item-group
                            v-for="(group, key) in groups"
                            :key="key"
                            :course="courseId"
                            :items="group"
                            :title="key"
                        ></video-list-item-group>
                    </ul>
                </div>
            </div>
        </div>
    </admin-layout>
</template>

<script>
import AdminLayout from '../layout/AdminLayout'
import { isAfter, parseISO } from 'date-fns'
import VideoPlayer from '../component/VideoPlayer'
import router from '../router'
import VideoListItem from '../component/VideoListItem'
import { toast } from '@/util/toast'
import { courseExpiration } from '@/util/courseExpiration'
import { filter, groupBy, head, isNil, path, pipe, prop } from 'ramda'
import VideoListItemGroup from '../component/VideoListItemGroup'
import Loader from '@/component/Loader.vue'
import { EventBus } from '@/main'

export default {
    beforeDestroy () {
        clearInterval(this.timer)
        EventBus.$off('locale-changed', this.fetchCourse)
        this.$store.commit('video/setCurrentVideo', {})
    },
    components: { Loader, VideoListItemGroup, VideoPlayer, VideoListItem, AdminLayout },
    computed: {
        course () {
            return this.$store.getters['course/selectCourse'](this.courseId)
        },
        courseId () {
            return this.$route.params.courseId
        },
        expiration () {
            return courseExpiration(this.date, parseISO(this.course.expire_at), this.$i18n.locale, this.$store.getters['auth/isAdmin'])
        },
        fetching () {
            return this.$store.getters['course/isFetching']
        },
        groups () {
            const noGroups = filter(pipe(prop('group_id'), isNil))(this.course.videos)
            const withGroups = pipe(filter(state => state?.group_id), groupBy(path(['group', 'title'])))(this.course.videos)       // filter(pipe(prop('group_id'), isNil, not))(this.course.videos)
            
            withGroups['null'] = noGroups
            
            return withGroups
            
            // return groupBy(path(['group', 'title']), this.course.videos)
        },
        video () {
            return this.$store.getters['video/selectCurrentVideo']
        },
        videoTitle () {
            return this.video?.title || 'Video'
        },
    },
    data () {
        return {
            date: new Date(),
            timer: null,
        }
    },
    mounted () {
        EventBus.$on('locale-changed', this.fetchCourse)
        
        if (this.course) {
            this.redirectIfExpired(this.course.expire_at)
            this.selectFirstVideo(this.course)
        }
        
        this.timer = setInterval(function () {
            this.date = new Date()
        }.bind(this), 1000)
    },
    methods: {
        fetchCourse () {
            this.$store.dispatch('course/fetchCourses')
        },
        redirectIfExpired (expireAt) {
            if (isAfter(this.date, parseISO(expireAt))) {
                toast.danger(this.$t('Kurz vypršal'))
                router.push({ name: 'dashboard' })
            }
        },
        selectFirstVideo (course) {
            this.$store.commit('video/setCurrentVideo', head(course.videos))
        },
    },
    name: 'CourseDetail',
    watch: {
        course: function (course) {
            if (! course) return
            
            this.redirectIfExpired(course?.expire_at)
            this.selectFirstVideo(course)
        },
        date: function () {
            if (! this.course?.expire_at) return
            
            this.redirectIfExpired(this.course.expire_at)
        },
    },
}
</script>
