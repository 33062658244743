<template>
    <li :class="`list-group-item ` + (currentVideo.id === video.id && 'active')" @click.prevent="playVideo">
        {{ video.title }}
    </li>
</template>

<script>
export default {
    computed: {
        currentVideo () {
            return this.$store.getters['video/selectCurrentVideo']
        },
    },
    methods: {
        playVideo () {
            this.$store.commit('video/setCurrentVideo', this.video)
        },
    },
    name: 'VideoListItem',
    props: {
        course: String,
        video: Object,
    },
}
</script>

<style lang="scss" scoped>
li {
    cursor: pointer;
}
</style>
